<template>
  <div>
    <b-table
      :items="items"
      :fields="computedFields"
      responsive
      striped
      bordered
      hover
      outlined
      class="rounded-table"
    >
      <template #cell(category)="data">
        <div>{{ data.value }}</div>
      </template>
      <template #cell(valor)="data">
        <div>{{ data.value }}</div>
      </template>
      <template #cell(qtde)="data">
        <div>{{ data.value }}</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { toCurrency } from "@/helpers/converters.js";

export default {
  name: "CommissionManagementTotalizer",
  props: {
    totalizer: {
      type: Object,
      required: true,
      validator: function (value) {
        const requiredKeys = [
          "solution_received",
          "solution_qtd",
          "ep_received",
          "ep_qtd",
          "sub_received",
          "sub_qtd",
        ];
        return requiredKeys.every((key) => key in value);
      },
    },
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    computedFields() {
      return [
        { key: "category", label: "Categoria", class: "text-center" },
        { key: "valor", label: "Valor", class: "text-center" },
        { key: "qtde", label: "Quantidade", class: "text-center" },
      ];
    },
  },
  created() {
    this.fillData();
  },
  watch: {
    totalizer: {
      handler(newVal) {
        this.fillData();
      },
      deep: true,
    },
  },
  methods: {
    fillData() {
      const defaultTotalizer = {
        solution_received: 0,
        solution_qtd: 0,
        ep_received: 0,
        ep_qtd: 0,
        sub_received: 0,
        sub_qtd: 0,
      };

      const data = this.totalizer || defaultTotalizer;

      this.items = [
        {
          category: "Soluções",
          valor: toCurrency(data.solution_received || 0),
          qtde: data.solution_qtd || 0,
        },
        {
          category: "Entrevista Paga",
          valor: toCurrency(data.ep_received || 0),
          qtde: data.ep_qtd || 0,
        },
        {
          category: "Assinatura",
          valor: toCurrency(data.sub_received || 0),
          qtde: data.sub_qtd || 0,
        },
        {
          category: "Total",
          valor: toCurrency(
            data.solution_received +
              data.ep_received +
              data.sub_received ||
              0
          ),
          qtde:
            data.solution_qtd + data.ep_qtd + data.sub_qtd || 0,
        },
      ];
    },
  },
};
</script>

<style scoped>
.text-center th {
  text-align: center;
}
.rounded-table {
  border-radius: 5px;
  /* overflow: hidden; */
  overflow-x: auto;
}
</style>
